/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import Backbone from '@sv/backbone';
import $ from '@sv/jquery';
import sv from '@sv/core';
import {
  ObjectUtil as objectUtil,
  Events as events,
  KeyUtil as keyUtil,
  Ajax as ajax,
} from '@sv/util';
import {
  getModelObjectUri,
  getPortletResourceUri,
  getTemplate,
  parseRequestParameters,
  getPortletUri,
} from '../util/portletUtil';

const KEY = keyUtil.KEY;
const i18cache = {};
const STORAGE_FULL_NAME_KEY = 'sv-comments2-full-name';
const inputConfig = {
  triggers: {
    '@': {
      source: getModelObjectUri(
        sv.PageContext.pageId,
        'userIdentitySearch',
        sv.PageContext.pageId
      ),
      searchKey: 'term',
      allowWhiteSpace: true,
      maxWhiteSpace: 2,
      parse: function (response) {
        return response.hits;
      },
    },
  },
  minLength: 2,
  replacer: function (item) {
    return (
      item.trigger + '{"value":"' + item.name + '","id":"' + item.id + '"}'
    );
  },
};
let maxCharacterCount;
let useCaptcha;
let reverseOrder;

// HELPERS ----------------------------------------------------
var i18n = function (selector, args) {
  var translated;

  if (!args && i18cache[selector]) {
    return i18cache[selector];
  }

  translated = sv.i18n.getText('portlet.comments2.comments', selector, args);

  if (!args) {
    i18cache[selector] = translated;
  }
  return translated;
};

var fixMentionLinks = function ($el, profilePageURL) {
  $el.find('a[data-user-identity]').each(function () {
    var $this = $(this);
    $this.attr(
      'href',
      profilePageURL + '?identity=' + $this.data('user-identity')
    );
  });
};

var fixTagLinks = function ($el, tagResultPageURL) {
  $el.find('a[data-social-tag-id]').each(function () {
    var $this = $(this);
    $this.attr(
      'href',
      tagResultPageURL +
        '?tag=' +
        encodeURIComponent($this.data('social-tag-name'))
    );
  });
};

var storeFullName = function (fullName) {
  if (fullName && 'localStorage' in window && window.localStorage !== null) {
    window.localStorage.setItem(STORAGE_FULL_NAME_KEY, fullName);
  }
};

var getFullName = function () {
  if ('localStorage' in window && window.localStorage !== null) {
    return window.localStorage.getItem(STORAGE_FULL_NAME_KEY);
  }
};

var Comment = Backbone.Model.extend({
  url: function () {
    if (this.collection) {
      return this.collection.url() + '&commentId=' + this.get('id');
    }

    return '?commentId=' + this.get('id');
  },
});

var Comments = Backbone.Collection.extend({
  model: Comment,

  initialize: function (collection, options) {
    this.options = options;

    if (sv.PageContext.userIdentityId) {
      this.bindServerEvents();
    }
  },

  bindServerEvents: function () {
    var that = this;
    //Listen for server events
    events.on('server-pagecomment-added-' + sv.PageContext.pageId, function (
      message
    ) {
      that.add(message.content, {
        append: true,
      });
    });
  },

  url: function () {
    return (
      getPortletResourceUri(this.options.portletId, 'comment') +
      '&file=' +
      this.options.id
    );
  },
});

var SubComment = Comment.extend({
  url: function () {
    if (this.collection) {
      return (
        getPortletResourceUri(this.collection.portletId, 'subComment') +
        '&commentId=' +
        this.collection.id +
        '&subCommentId=' +
        this.get('id')
      );
    }

    return '?subCommentId=' + this.get('id');
  },
});

var SubComments = Backbone.Collection.extend({
  model: SubComment,

  initialize: function (models, options) {
    this.id = options.id;
    this.portletId = options.portletId;
    this.pageCommentId = options.pageCommentId;
  },

  url: function () {
    return (
      getPortletResourceUri(this.portletId, 'subComments') +
      '&commentId=' +
      this.pageCommentId
    );
  },

  parse: function (data) {
    this.isAllowedToComment = data.isAllowedToComment;
    this.trigger('updateCommentArea', this.isAllowedToComment);
    return data;
  },
});

var CommentView = Backbone.View.extend({
  tagName: 'li',

  className: function () {
    return this.model.get('hidden')
      ? 'sv-comment2-comment sv-comment-hidden'
      : 'sv-comment2-comment';
  },

  events: function () {
    if (this.model.get('isSubComment')) {
      return {
        'click [data-fn-destroy-subcomment]': 'showDestroyDialog',
        'click [data-fn-edit-subcomment]': 'editComment',
        'click [data-fn-hide-subcomment]': 'doHide',
        'click [data-fn-show-subcomment]': 'doHide',
      };
    }

    return {
      'click [data-fn-destroy-comment]': 'showDestroyDialog',
      'click [data-fn-edit-comment]': 'editComment',
      'click [data-fn-hide-comment]': 'doHide',
      'click [data-fn-show-comment]': 'doHide',
      'click [data-fn-show-comments]': 'handleShowCommentsClick',
      'click [data-fn-expand-subcomments]': 'handleShowCommentsClick',
      'click [data-fn-shrink-subcomments]': 'handleHideCommentsClick',
    };
  },

  initialize: function () {
    this.listenTo(this.model, 'change:comment', this.updateComment, this);
    this.listenTo(this.model, 'destroy', this.remove, this);
    this.listenTo(this.model, 'change:hidden', this.hide, this);
  },

  showDestroyDialog: function () {
    var that = this,
      title = this.model.get('isSubComment')
        ? i18n('removeReplyTitle')
        : i18n('removeCommentTitle'),
      body = this.model.get('isSubComment')
        ? i18n('removeReplyBody')
        : i18n('removeCommentBody');

    sv.DialogUtil.showConfirmDialog(title, body, function (success) {
      if (success) {
        that.model.destroy({
          wait: true,
        });
      }
    });

    return false;
  },

  editComment: function (e) {
    e.preventDefault();

    ajax
      .doGet({
        context: this,
        url: this.model.url(),
      })
      .done(function (response) {
        this.showEditComment(response.plainMessage, response.mentions);
      });

    return false;
  },

  showEditComment: function (message, mentions) {
    var that = this,
      modalContent = $('<div/>');

    var $charactersLeft = $(
      '<span class="sv-float-right">' +
        (maxCharacterCount - message.length) +
        '</span>'
    );

    var $commentField = $('<textarea/>')
      .attr('id', 'editComment')
      .addClass('sv-comment-input sv-border-box')
      .on('keyup', (e) => {
        var charactersLeft = maxCharacterCount - e.currentTarget.value.length;

        if (charactersLeft < 0) {
          $charactersLeft.addClass('sv-character-limit-exceeded');
          this.getSaveEditCommentButton()
            .addClass('disabled')
            .attr('disabled', 'disabled');
        } else {
          $charactersLeft.removeClass('sv-character-limit-exceeded');
          this.getSaveEditCommentButton()
            .removeClass('disabled')
            .prop('disabled', false);
        }

        $charactersLeft.text(charactersLeft);
      })
      .val(message);

    modalContent
      .addClass('sv-comments2-portlet')
      .append($commentField)
      .append($charactersLeft);

    var buttons = [
      {
        text: i18n('save'),
        callback: $.proxy(function () {
          ajax
            .doPut({
              url: that.model.url(),
              data: {
                comment: $commentField.triggeredInput('getRichContent'),
              },
              context: this,
            })
            .done(function (updatedComment) {
              this.model.set(updatedComment);
            });
        }, this),
      },
    ];

    sv.DialogUtil.showDialog({
      title: this.model.get('isSubComment')
        ? i18n('editReplyTitle')
        : i18n('editCommentTitle'),
      body: modalContent,
      buttons: buttons,
      shownCallback: function () {
        $commentField
          .triggeredInput(inputConfig)
          .triggeredInput('setIds', mentions)
          .elastic();
      },
    });

    return false;
  },

  getSaveEditCommentButton: function () {
    if (!this.$saveEditButton) {
      this.$saveEditButton = $('.modal-footer button');
    }

    return this.$saveEditButton;
  },

  updateComment: function (model) {
    if (model.get('hidden') && !model.get('isAdmin')) {
      model.collection.remove(model);
    } else {
      this.render();
      events.trigger(events.types.updateLikables, this.$el);
      events.trigger(events.types.updateRelativeDates, this.$el);
    }
  },

  doHide: function (e) {
    e.preventDefault();

    this.model.set({
      hidden: !this.model.get('hidden'),
    });
    this.model.save(
      {},
      {
        url:
          getPortletResourceUri(
            this.options.portletId,
            'toggleVisibleComment'
          ) +
          '&commentId=' +
          this.model.id,
      }
    );
  },

  updateCommentsCount: function (newCount) {
    var replyString;
    switch (newCount) {
      case 0:
        replyString = i18n('doReply');
        break;
      case 1:
        replyString = newCount + ' ' + i18n('reply');
        break;
      default:
        replyString = newCount + ' ' + i18n('replies');
    }

    this.$el.find('[data-fn-subcomment-counter]').text(replyString);
  },

  handleShowCommentsClick: function (e) {
    e.preventDefault();
    this.doShowComments();
  },

  handleHideCommentsClick: function (e) {
    e.preventDefault();
    var $subCommentsContainer = this.$el.find(
        '[data-comments2-subcomment-container]'
      ),
      $expand = this.$el.find('[data-fn-expand-subcomments]'),
      $shrink = this.$el.find('[data-fn-shrink-subcomments]');

    $subCommentsContainer.fadeOut('fast');
    $shrink.hide();
    $expand.show();
  },

  doShowComments: function () {
    var pageCommentId = this.model.get('id'),
      $subCommentsContainer = this.$el.find(
        '[data-comments2-subcomment-container]'
      ),
      $expand = this.$el.find('[data-fn-expand-subcomments]'),
      $shrink = this.$el.find('[data-fn-shrink-subcomments]'),
      that = this;

    if (!this.subComments) {
      this.subComments = new SubComments([], {
        id: this.model.id,
        portletId: this.options.portletId,
        pageCommentId: pageCommentId,
      });

      this.subCommentsView = new CommentsView({
        el: $subCommentsContainer,
        collection: this.subComments,
        commentTemplate: getTemplate(
          $('#' + sv.ObjectUtil.getHtmlId(this.options.portletId)),
          'comments2-subcomment'
        ),
        portletId: this.options.portletId,
        profilePageURL: this.options.profilePageURL,
        tagResultPageURL: this.options.tagResultPageURL,
        $list: $subCommentsContainer.find('[data-comments2-subcomments]'),
      });

      this.listenTo(this.subComments, 'add destroy', function () {
        that.updateCommentsCount.call(that, that.subComments.length);
      });

      this.subComments.fetch({
        success: function (list) {
          that.subCommentsView.render();
          that.updateCommentsCount(list.length);
          $subCommentsContainer.fadeIn('fast', function () {
            $subCommentsContainer.find('textarea').triggerHandler('focus');
          });

          //$subCommentsContainer.show();
          $shrink.show();
          $expand.hide();
        },
      });
    } else {
      $subCommentsContainer.fadeIn('fast');
      $shrink.show();
      $expand.hide();
    }
  },

  hide: function () {
    if (this.model.get('hidden')) {
      var hiddenI18nKey = this.model.get('isSubComment')
        ? 'hiddenReply'
        : 'hiddenComment';

      this.$el.addClass('sv-comment-hidden');
      this.$el.attr('title', i18n(hiddenI18nKey));
      this.$el.find('[data-fn-hide-comment]').addClass('sv-hidden');
      this.$el.find('[data-fn-show-comment]').removeClass('sv-hidden');
      this.$el.find('[data-fn-hide-subcomment]').addClass('sv-hidden');
      this.$el.find('[data-fn-show-subcomment]').removeClass('sv-hidden');
      this.$el
        .find('.sv-comments2-hidden-comment-text')
        .removeClass('sv-hidden');
    } else {
      this.$el.removeClass('sv-comment-hidden');
      this.$el.removeAttr('title');
      this.$el.find('[data-fn-hide-comment]').removeClass('sv-hidden');
      this.$el.find('[data-fn-show-comment]').addClass('sv-hidden');
      this.$el.find('[data-fn-hide-subcomment]').removeClass('sv-hidden');
      this.$el.find('[data-fn-show-subcomment]').addClass('sv-hidden');
      this.$el.find('.sv-comments2-hidden-comment-text').addClass('sv-hidden');
    }
  },

  render: function () {
    this.$el.html(this.options.template(this.model.toJSON()));
    if (this.model.toJSON().hidden) {
      var hiddenI18nKey = this.model.get('isSubComment')
        ? 'hiddenReply'
        : 'hiddenComment';

      this.$el.attr('title', i18n(hiddenI18nKey));
    }

    fixMentionLinks(this.$el, this.options.profilePageURL);
    fixTagLinks(this.$el, this.options.tagResultPageURL);

    if (this.model.id === parseRequestParameters('pagecomment')) {
      this.doShowComments();
    }

    return this;
  },
});

var CommentsView = Backbone.View.extend({
  initialize: function () {
    this.$list = this.options.$list;
    this.collection.on('add', this.addNewComment, this);
    this.collection.on('remove', this.removeComment, this);

    this.$el
      .find('[data-fn-comments2-input]')
      .triggeredInput(inputConfig)
      .elastic();

    if (this.collection.length > 0) {
      this.render();
    }
  },

  render: function () {
    this.clearList();
    this.collection.each(function (comment) {
      this.appendOne(comment, false);
    }, this);

    events.trigger(events.types.updateRelativeDates, this.$el);
    events.trigger(events.types.updateLikables, this.$el);

    if (window.onloadCallback && this.collection.id) {
      window.onloadCallback(this.$el.find('[data-sv-recaptcha]').attr('id'));
    }
  },

  clearList: function () {
    this.$list.empty();
  },

  appendOne: function (comment, prepend) {
    var view = new CommentView({
      model: comment,
      portletId: this.options.portletId,
      template: this.options.commentTemplate,
      profilePageURL: this.options.profilePageURL,
      tagResultPageURL: this.options.tagResultPageURL,
    });

    var commentEl = view.render().$el,
      commentContentObj = commentEl.find('.sv-comments2-content'),
      truncate = commentContentObj.find('.sv-truncate-more'),
      split;

    if (truncate.length > 0) {
      this.addShowMoreLink(truncate);
      split = true;
    }
    prepend ? this.$list.prepend(commentEl) : this.$list.append(commentEl);

    if (split) {
      this.bindShowMoreEvents(commentEl);
    }
  },

  bindShowMoreEvents: function (obj) {
    var options = {
      moreText: i18n('showMore'),
      lessText: i18n('showLess'),
    };
    var moreLink = $('.sv-truncate-more-link', obj),
      moreContent = $('.sv-truncate-more', obj),
      ellipsis = $('.sv-truncate-ellipsis', obj);

    moreLink.on('click', function (e) {
      e.preventDefault();
      if (moreLink.text() === options.moreText) {
        moreContent.show();
        moreLink.text(options.lessText);
        ellipsis.css('display', 'none');
      } else {
        moreContent.hide();
        moreLink.text(options.moreText);
        ellipsis.css('display', 'inline');
      }
    });
  },

  addShowMoreLink: function (obj) {
    $('<span class="sv-truncate-ellipsis">...</span>').insertBefore(obj);
    $(
      '<div><a href="#" class="sv-truncate-more-link">' +
        i18n('showMore') +
        '</a></div>'
    ).insertAfter(obj);
    obj.css('display', 'none');
  },

  addNewComment: function (entry) {
    if (entry.toJSON().author !== undefined) {
      this.appendOne(entry, reverseOrder);
    } else {
      events.trigger(events.types.notifyUser, {
        type: 'default',
        heading: i18n('moderatedHeading'),
        message: i18n('moderatedBody'),
        transient: true,
      });
    }
    events.trigger(events.types.updateRelativeDates, this.$el);
    events.trigger(events.types.updateLikables, this.$el);
  },

  removeComment: function (comment) {
    this.render();

    if (comment.get('hidden')) {
      events.trigger(events.types.notifyUser, {
        type: 'default',
        heading: i18n('moderatedHeading'),
        message: i18n('moderatedBody'),
        transient: true,
      });
    }
  },

  getLoader: function () {
    if (!this.$loader) {
      this.$loader = $(
        '<img src="/sitevision/util/images/loading_16_grey.gif" />'
      )
        .css('vertical-align', 'middle')
        .appendTo(this.$el.find('[data-fn-comment-input]').parent());
    }

    return this.$loader;
  },

  events: {
    'focus [data-fn-comments2-input]': 'expandTextfield',
    'click [data-fn-comments2-input]': 'expandTextfield',
    'keyup [data-fn-comments2-input]': 'countdownCharactersLeft',
    'keyup [data-fn-comments2-input-name]': 'countdownCharactersLeft',
    'keydown [data-fn-comments2-input]': 'checkKeyDown',
    'submit [data-fn-comments2-form]': 'postComment',
  },

  expandTextfield: function (e) {
    var $currentTarget = $(e.currentTarget);

    $currentTarget.addClass('sv-message-input-expanded');
    this.getInputControls($currentTarget).show();
    this.countdownCharactersLeft(e);
  },

  shrinkTextfield: function ($currentTarget) {
    $currentTarget.removeClass('sv-message-input-expanded');
    this.getInputControls($currentTarget).hide();
  },

  getTextField: function ($inputForm) {
    return $inputForm.find('[data-fn-comments2-input]');
  },

  getInputControls: function ($commentField) {
    return $commentField.closest('form').find('.sv-submit-controls');
  },

  countdownCharactersLeft: function (e) {
    var $target = $(e.currentTarget),
      text = $target.val(),
      size = maxCharacterCount - text.length,
      $sizeField = $target.parent().find('[data-fn-comments2-input-size]'),
      $inputForm = $target.closest('form');

    if (size < 0) {
      $sizeField.addClass('sv-character-limit-exceeded');
    } else {
      $sizeField.removeClass('sv-character-limit-exceeded');
    }

    $sizeField.text(size);

    if ($target.val() !== '' && size >= 0 && !this.activeConnection) {
      this.enableSubmitButton($inputForm);
    } else {
      this.disableSubmitButton($inputForm);
    }
  },

  checkKeyDown: function (e) {
    var key = keyUtil.getKeyCodeFromEvent(e);
    if (key === KEY.RETURN && e.ctrlKey) {
      this.getSubmitButton($(e.target).closest('form')).trigger('focus');
      this.$el.find('[data-fn-comments2-form]').trigger('submit');
      return false;
    }
  },

  postComment: function (e) {
    var that = this,
      field,
      comment,
      commentLength,
      loaderTimeout,
      nameField,
      recaptchaResponse;

    // Have to check if default is prevented. Because Firefox will run keyPress event
    // even if default is prevented. Other browsers will not... So to prevent the comment
    // from being posted when selecting a mention we have to run this check...
    if (!(e.isPropagationStopped() || e.isDefaultPrevented())) {
      e.preventDefault(); // Make sure form isn't POSTed
      if (this.activeConnection) {
        return false;
      }
      var $form = $(e.target);

      field = this.getTextField($form);
      nameField = that.getNameField($form);

      commentLength = field.val().length;

      if (nameField.length === 1 && nameField.val().length === 0) {
        events.trigger(events.types.notifyUser, {
          type: 'default',
          heading: i18n('noNameHeading'),
          message: i18n('noNameBody'),
          transient: true,
        });
        return false;
      }

      if (commentLength > 0 && commentLength <= maxCharacterCount) {
        if (useCaptcha) {
          var responseElem = this.$el.find('#g-recaptcha-response');

          if (!responseElem.length) {
            //subcomment...
            responseElem = this.$el.find('.g-recaptcha-response');
          }
          recaptchaResponse = responseElem && responseElem[0].value;

          if (!recaptchaResponse) {
            this.$el.find('.sv-help-block-verify').show();
            return false;
          }
        }
        this.disableSubmitButton($(e.target));
        this.activeConnection = true;

        if (this.collection.id) {
          comment = new SubComment({
            comment: field.triggeredInput('getRichContent'),
            fullName: nameField.val(),
            useCaptcha: useCaptcha,
            recaptchaResponse: recaptchaResponse || '',
          });
        } else {
          comment = new Comment({
            comment: field.triggeredInput('getRichContent'),
            fullName: nameField.val(),
            useCaptcha: useCaptcha,
            recaptchaResponse: recaptchaResponse || '',
          });
        }
        var email = that.$el.find('[data-sv-spam-marker]');

        if (email.length > 0) {
          comment.attributes[email.attr('name')] = email.val();
        }

        loaderTimeout = setTimeout(function () {
          that.getLoader().show();
        }, 400);

        this.collection.create(comment, {
          wait: true,
          success: function () {
            field.val('');
            that.activeConnection = false;
            that.getLoader().hide();
            clearTimeout(loaderTimeout);
            storeFullName(nameField.val());
            if (useCaptcha) {
              if (that.collection.id) {
                that.$el.find('[data-sv-recaptcha-container]').hide();
              } else {
                that.$el.find('#recaptcha-container').hide();
              }
            }
          },
          error: function () {
            that.activeConnection = false;
            that.getLoader().hide();
            clearTimeout(loaderTimeout);
            that.enableSubmitButton($(e.target));
            events.trigger(events.types.notifyUser, {
              type: 'error',
              heading: i18n('postFailedHeading'),
              message: i18n('postFailedBody'),
              transient: true,
            });
          },
        });
        this.shrinkTextfield(field);
      } else if (commentLength > maxCharacterCount) {
        events.trigger(events.types.notifyUser, {
          type: 'default',
          heading: i18n('commentToLongHeading'),
          message: i18n('commentToLongBody'),
          transient: true,
        });
      }
      return false;
    }
  },

  getSubmitButton: function ($inputForm) {
    return $inputForm.find('[data-fn-comments2-submit]');
  },

  getNameField: function ($inputForm) {
    return $inputForm.find('[data-fn-comments2-input-name]');
  },

  disableSubmitButton: function ($inputForm) {
    this.getSubmitButton($inputForm)
      .addClass('disabled')
      .attr('disabled', 'disabled');
  },

  nameFieldOK: function ($inputForm) {
    var nameField = this.getNameField($inputForm);
    if (nameField.length !== 0 && !nameField.val()) {
      return false;
    }
    return true;
  },

  enableSubmitButton: function ($inputForm) {
    var textField = this.getTextField($inputForm);

    if (textField.val() !== '' && this.nameFieldOK($inputForm)) {
      this.getSubmitButton($inputForm)
        .removeClass('disabled')
        .prop('disabled', false);
    }
  },
});

$('.sv-comments2-portlet').each(function () {
  var $portlet = $(this);
  import(/* webpackChunkName: "comments2-plugins" */ './plugins').then(() => {
    initCommentPortlet($portlet);
  });
});

function initCommentPortlet($portlet) {
  var $commentEntry = $portlet.find('[data-comments2-entry]');
  initCommentPortletActions($portlet);
  if ($commentEntry.length === 0) {
    return;
  }

  var portletId = objectUtil.getObjectId($portlet.attr('id'));
  var jsCommentsNamespace = $commentEntry.data('js-namespace'),
    commentableId = portletId,
    profilePageURL = $commentEntry.data('profilepageurl'),
    tagResultPageURL = $commentEntry.data('tagresultpageurl'),
    comments = new Comments(sv[jsCommentsNamespace].comments || [], {
      id: commentableId,
      portletId: portletId,
    });

  maxCharacterCount = sv[jsCommentsNamespace].maxCharacterCount;
  useCaptcha = sv[jsCommentsNamespace].useCaptcha;
  reverseOrder = sv[jsCommentsNamespace].reverseOrder;

  new CommentsView({
    el: $portlet,
    collection: comments,
    commentTemplate: getTemplate($portlet, 'comments2-comment'),
    portletId: portletId,
    profilePageURL: profilePageURL,
    tagResultPageURL: tagResultPageURL,
    $list: $portlet.find('[data-fn-comments2]'),
  });

  var $commentsContainer = $portlet.find('.sv-comments2-comments');

  if ($commentsContainer.length > 0) {
    fixMentionLinks($commentsContainer, profilePageURL);
    fixTagLinks($commentsContainer, tagResultPageURL);
  }

  var storedName = getFullName();

  if (storedName && storedName.length > 0) {
    $portlet.find('[data-fn-comments2-input-name]').val(storedName);
  }
  $portlet.find('.sv-comment-hidden').attr('title', i18n('hiddenComment'));
}

function initCommentPortletActions($portlet) {
  var portletId = objectUtil.getObjectId($portlet.attr('id'));
  $portlet.off('click', '[data-fn-comment2-toggle-close]');
  $portlet.on('click', '[data-fn-comment2-toggle-close]', function (e) {
    e.preventDefault();
    var url = getPortletUri(portletId, 'toggleVisible');
    $.get(url, function (data) {
      $portlet.find('.sv-comments2-portlet-content').html(data);
      initCommentPortlet($portlet);
    });
  });

  $portlet.off('click', '[data-fn-comment2-toggle-mutable]');
  $portlet.on('click', '[data-fn-comment2-toggle-mutable]', function (e) {
    e.preventDefault();
    var url = getPortletUri(portletId, 'toggleMutable');
    $.get(url, function () {
      if (
        $portlet.find('[data-sv-comments2-closed-title]').hasClass('sv-hidden')
      ) {
        $portlet
          .find('[data-sv-comments2-closed-title]')
          .removeClass('sv-hidden');
        $portlet.find('.sv-comments2-form').addClass('sv-hidden');
        $portlet
          .find('[data-sv-comments2-disallow-comments]')
          .addClass('sv-hidden');
        $portlet
          .find('[data-sv-comments2-allow-comments]')
          .removeClass('sv-hidden');
      } else {
        $portlet.find('[data-sv-comments2-closed-title]').addClass('sv-hidden');
        $portlet.find('.sv-comments2-form').removeClass('sv-hidden');
        $portlet
          .find('[data-sv-comments2-disallow-comments]')
          .removeClass('sv-hidden');
        $portlet
          .find('[data-sv-comments2-allow-comments]')
          .addClass('sv-hidden');
      }
    });
  });
}
