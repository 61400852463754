/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Niclas Hedlund
 */

import $ from '@sv/jquery';
import _ from '@sv/underscore';
import {
  Log as log,
  ObjectUtil as objectUtil,
  KeyUtil as keyUtil,
  i18n,
} from '@sv/util';
import { getPortletResourceUri } from '../../util/portletUtil';
let searchTimer;

const showSearchResultPopup = function (anOptions) {
  // Global variables
  const portletId = anOptions.portletId,
    htmlPortletId = objectUtil.getHtmlId(portletId),
    inputField = anOptions.inputField,
    resultDiv = anOptions.resultDiv,
    portletDiv = anOptions.portletDiv;

    let selectedRowIndex = -1,
      selectedColumnIndex = -1;

  const messageTemplate =
    '<div class="sv-searchform2-message">' +
    '   <<%= noHitsTag %> class="<%= noHitsClass %>"><%= noHitsText %></<%= noHitsTag %>>' +
    '</div>';

  // Get user template
  const getUserTemplate = function (columnIndex, columnWidth) {
    const ariaLabel = i18n.getText(
      'portlet.search.searchform2.searchform2',
      'users'
    );
    const userTemplate =
      '<div id="' +
      htmlPortletId +
      '_sv-user-search-results"" aria-label="' +
      ariaLabel +
      '" class="sv-border-box sv-searchform2-content-container">' +
      '   <<%= facetTag %> tabindex="0" class="sv-searchform2-content-header <%= facetTagClass %>"><%- name %></<%= facetTag %>>' +
      '   <div class="sv-border-box sv-searchform2-facet-column' +
      columnIndex +
      '" style="width: ' +
      columnWidth +
      'px">' +
      '      <ul aria-labelledby="' +
      htmlPortletId +
      '_sv-user-search-results"" role="listbox" id="' +
      htmlPortletId +
      '_user_results" class="sv-defaultlist sv-user-search-list sv-searchform2-search-list sv-searchform2-search-list-column' +
      columnIndex +
      '">' + // Use sv-user-search-list for hoover effect
      '         <% _.each(hits, function(hit, index){ %>' +
      '            <li aria-selected="false" role="option" id="' +
      htmlPortletId +
      '_user_<%= index %>" class="sv-clearfix sv-searchform2-search-hit" >' +
      '               <img class="sv-buddy-icon sv-searchform2-user-img" src="<%= hit.iconURL %>" />' +
      '               <% if (hit.isContact) { %><div style="float:right"><i class="halflings-icon ok"></i></div><% } %>' +
      '               <div class="sv-searchform2-user-container">' +
      '                  <<%= userBlockTag %> class="sv-searchform2-user-name <%= userTagClass %>"><a href="<%= hit.profileURL %>" onclick="<%= hit.onClick %>"><%= hit.name %></a></<%= userBlockTag %>>' +
      "                  <% if (typeof(hit.userfields) !== 'undefined') { %>" +
      '                     <ul class="sv-searchform2-userfield-list">' +
      '                        <% _.each(hit.userfields, function(userfield){ %>' +
      '                           <li class="<%= textTagClass %> sv-wrap-words"><%= userfield.value %></li>' +
      '                        <% }); %>' +
      '                     </ul>' +
      '                  <% } %>' +
      '               </div>' +
      '            </li>' +
      '         <% }); %>' +
      '         <% if (showDetails) { %>' +
      '            <li id="' +
      htmlPortletId +
      '_user_more" aria-selected="false" role="option" class="sv-clearfix sv-searchform2-search-hit sv-searchform2-more-hits"><<%= textTag %> class="<%= textTagClass %>"><a href="<%= showDetailsURL %>"><%= showDetailsText %></a></<%= textTag %>></li>' +
      '         <% } %>' +
      '      </ul>' +
      '   </div>' +
      '</div>';
    return userTemplate;
  };

  // Get ugc template
  const getUgcTemplate = function (columnIndex, columnWidth) {
    const ariaLabel = i18n.getText(
      'portlet.search.searchform2.searchform2',
      'ugc'
    );
    const ugcTemplate =
      '<div id="' +
      htmlPortletId +
      '_sv-ugc-search-results"" aria-label="' +
      ariaLabel +
      '" class="sv-border-box sv-searchform2-facet-column' +
      columnIndex +
      '" style="width: ' +
      columnWidth +
      'px">' +
      '   <ul aria-labelledby="' +
      htmlPortletId +
      '_sv-ugc-search-results"" role="listbox" id="' +
      htmlPortletId +
      '_ugc_results" class="sv-defaultlist sv-user-search-list sv-searchform2-search-list sv-searchform2-search-list-column' +
      columnIndex +
      '">' + // Use sv-user-search-list for hoover effect
      '      <% _.each(hits, function(hit, index){ %>' +
      '         <li aria-selected="false" role="option" id="' +
      htmlPortletId +
      '_ugc_<%= index %>" class="sv-clearfix sv-searchform2-search-hit sv-wrap-words">' +
      '            <img class="sv-buddy-icon sv-searchform2-user-img" src="<%= hit.iconURL %>" />' +
      '            <% if (hit.isContact) { %><div style="float:right"><i class="halflings-icon ok"></i></div><% } %>' +
      '            <div class="sv-searchform2-user-container">' +
      '               <<%= userBlockTag %> class="sv-searchform2-user-name <%= userTagClass %>"><a href="<%= hit.entryURL %>" onclick="<%= hit.onClick %>"><%= hit.entryText %></a></<%= userBlockTag %>>' +
      "               <% if (typeof(hit.userfields) !== 'undefined') { %>" +
      '                  <ul class="sv-searchform2-userfield-list">' +
      '                     <% _.each(hit.userfields, function(userfield){ %>' +
      '                        <li class="<%= textTagClass %> sv-wrap-words"><%= userfield.value %></li>' +
      '                     <% }); %>' +
      '                  </ul>' +
      '               <% } %>' +
      '            </div>' +
      '         </li>' +
      '      <% }); %>' +
      '      <% if (showDetails) { %>' +
      '         <li id="' +
      htmlPortletId +
      '_ugc_more" aria-selected="false" role="option" class="sv-clearfix sv-searchform2-search-hit sv-searchform2-more-hits"><<%= textTag %> class="<%= textTagClass %>"><a href="<%= showDetailsURL %>"><%= showDetailsText %></a></<%= textTag %>></li>' +
      '      <% } %>' +
      '   </ul>' +
      '</div>';
    return ugcTemplate;
  };

  // Get content template
  const getContentTemplate = function (columnIndex, columnWidth) {
    const ariaLabel = i18n.getText(
      'portlet.search.searchform2.searchform2',
      'content'
    );
    const contentTemplate =
      '<div aria-label="' +
      ariaLabel +
      '" id="' +
      htmlPortletId +
      '_sv-content-search-results" class="sv-border-box sv-searchform2-facet-column' +
      columnIndex +
      '" style="width: ' +
      columnWidth +
      'px">' +
      '   <ul aria-labelledby="' +
      htmlPortletId +
      '_sv-content-search-results" role="listbox" id="' +
      htmlPortletId +
      '_content_results" class="sv-defaultlist sv-user-search-list sv-searchform2-search-list sv-searchform2-search-list-column' +
      columnIndex +
      '">' + // Use sv-user-search-list for hoover effect
      '      <% _.each(hits, function(hit, index){ %>' +
      '         <li aria-selected="false" role="option" id="' +
      htmlPortletId +
      '_content_<%= index %>" class="sv-searchform2-search-hit sv-wrap-words"><a class="sv-vamiddle <%= contentTagClass %>" href="<%= hit.URL %>" onclick="<%= hit.onClick %>"><%= hit.name %></a><% if (typeof(hit.iconURL) !== \'undefined\') { %><img alt="<%= hit.iconDescription %>" class="sv-noborder-vamiddle" src="<%= hit.iconURL %>" style="margin-left: 3px;"><% } %></li>' +
      '      <% }); %>' +
      '      <% if (showDetails) { %>' +
      '         <li id="' +
      htmlPortletId +
      '_content_more" aria-selected="false" role="option" class="sv-clearfix sv-searchform2-search-hit sv-searchform2-more-hits" role="option"><<%= textTag %> class="<%= textTagClass %>"><a href="<%= showDetailsURL %>"><%= showDetailsText %></a></<%= textTag %>></li>' +
      '      <% } %>' +
      '   </ul>' +
      '</div>';
    return contentTemplate;
  };

  // Hide result div when clicking outside popup
  const closeResultDivFunction = function () {
    resultDiv.hide();
  };
  $('body').on('click', closeResultDivFunction);

  // Determine alignment of the search result
  const getAlignment = function (resultDiv) {
    if (resultDiv.hasClass('sv-searchform2-result-container-alignment-left')) {
      return 'left';
    } else {
      return 'right';
    }
  };

  // Determine column index for a specific row
  const getColumnIndexForRow = function (row) {
    const column = row.parent();

    if (column.hasClass('sv-searchform2-search-list-column0')) {
      return 0;
    } else if (column.hasClass('sv-searchform2-search-list-column1')) {
      return 1;
    } else if (column.hasClass('sv-searchform2-search-list-column2')) {
      return 2;
    } else {
      return -1;
    }
  };

  // Determine if a column has a row for a specific index
  const hasRowForIndex = function (rowIndex, column) {
    return rowIndex < getRowCount(column);
  };

  // Get index in column for a specific row
  const getIndexForRow = function (row) {
    return row.index();
  };

  // Get the column for a specific column index
  const getColumnForIndex = function (columnIndex, resultDiv) {
    if (columnIndex > -1) {
      var column = resultDiv.find(
        '.sv-searchform2-search-list-column' + columnIndex
      );

      if (column.length === 1) {
        return column;
      }
    }

    return;
  };

  // Get the row at a specific index in a column
  const getRowForIndex = function (rowIndex, column) {
    if (rowIndex > -1 && rowIndex < column.children().length) {
      return $(column.children()[rowIndex]);
    } else {
      return;
    }
  };

  // Get the row located at a specific column and row index
  const getColumnRowForIndex = function (columnIndex, rowIndex, resultDiv) {
    const column = getColumnForIndex(columnIndex, resultDiv);

    if (column !== undefined) {
      return getRowForIndex(rowIndex, column);
    } else {
      return;
    }
  };

  // Count the number of rows in a column
  const getRowCount = function (column) {
    return column.children().length;
  };

  // Count the number of columns in the result div
  const getColumnCount = function (resultDiv) {
    let count = 0;
    const column0 = getColumnForIndex(0, resultDiv),
      column1 = getColumnForIndex(1, resultDiv),
      column2 = getColumnForIndex(2, resultDiv);

    if (column0 !== undefined) {
      count++;
    }

    if (column1 !== undefined) {
      count++;
    }

    if (column2 !== undefined) {
      count++;
    }

    return count;
  };

  // Mark all rows in a column as inactive
  const markColumnAsInactive = function (column) {
    if (!column) {
      return;
    }

    const $children = column.children();

    $children.removeClass('active').attr('aria-selected', false);

    inputField.removeAttr('aria-activedescendant');
  };

  // Mark row as active
  const markRowAsActive = function ($row) {
    $row.addClass('active').attr('aria-selected', true);

    inputField.attr('aria-activedescendant', $row.attr('id'));
  };

  // Mark row as inactive
  const markRowAsInactive = function ($row) {
    $row.removeClass('active').attr('aria-selected', false);

    inputField.removeAttr('aria-activedescendant');
  };

  // Get the URL associated with a row
  const getRowURL = function (row) {
    return row.find('a').attr('href');
  };

  // Listen for keyboard activity and trigger search
  inputField
    .on('keydown', function (e) {
      var keyCode = keyUtil.getKeyCodeFromEvent(e);

      if (keyCode === keyUtil.KEY.RETURN) {
        e.preventDefault();
        return false;
      }

      const column0 = getColumnForIndex(0, resultDiv),
        column1 = getColumnForIndex(1, resultDiv),
        column2 = getColumnForIndex(2, resultDiv);

      // Move left or right between columns
      if (column0 !== undefined && column1 !== undefined) {
        if (keyCode === keyUtil.KEY.RIGHT && selectedColumnIndex === 0) {
          selectedColumnIndex = 1;
          selectedRowIndex = -1;
          markColumnAsInactive(column0);
          markColumnAsInactive(column2);
          keyCode = keyUtil.KEY.DOWN; // Trigger selection of the first row in the column
        } else if (keyCode === keyUtil.KEY.LEFT && selectedColumnIndex === 1) {
          selectedColumnIndex = 0;
          selectedRowIndex = -1;
          markColumnAsInactive(column1);
          markColumnAsInactive(column2);
          keyCode = keyUtil.KEY.DOWN; // Trigger selection of the first row in the column
        } else if (keyCode === keyUtil.KEY.RIGHT && selectedColumnIndex === 1) {
          selectedColumnIndex = 2;
          selectedRowIndex = -1;
          markColumnAsInactive(column0);
          markColumnAsInactive(column1);
          keyCode = keyUtil.KEY.DOWN; // Trigger selection of the first row in the column
        } else if (keyCode === keyUtil.KEY.LEFT && selectedColumnIndex === 2) {
          selectedColumnIndex = 1;
          selectedRowIndex = -1;
          markColumnAsInactive(column0);
          markColumnAsInactive(column2);
          keyCode = keyUtil.KEY.DOWN; // Trigger selection of the first row in the column
        }
      }

      // Move up or down in rows in the selected column
      if (keyCode === keyUtil.KEY.DOWN || keyCode === keyUtil.KEY.UP) {
        // Determine which column that is selected using 'selectedColumnIndex' (or select an appropriate one if no one already has been selected)
        let column;

        if (selectedColumnIndex === 0 && column0 !== undefined) {
          column = column0;
        } else if (selectedColumnIndex === 1 && column1 !== undefined) {
          column = column1;
        } else if (selectedColumnIndex === 2 && column2 !== undefined) {
          column = column2;
        } else if (selectedColumnIndex === -1) {
          if (getAlignment(resultDiv) === 'left') {
            if (column0 !== undefined) {
              selectedColumnIndex = 0;
              column = column0;
            }
          } else if (column0 !== undefined) {
            selectedColumnIndex = 0;
            column = column0;
          } else if (column1 !== undefined) {
            selectedColumnIndex = 1;
            column = column1;
          }
        }

        if (
          keyCode === keyUtil.KEY.DOWN &&
          column !== undefined &&
          hasRowForIndex(selectedRowIndex + 1, column)
        ) {
          var nextRow = getRowForIndex(selectedRowIndex + 1, column);
          selectedRowIndex++;
          markColumnAsInactive(column);
          markRowAsActive(nextRow);
        } else if (
          keyCode === keyUtil.KEY.UP &&
          column !== undefined &&
          selectedRowIndex > -1
        ) {
          selectedRowIndex--;
          markColumnAsInactive(column);

          if (selectedRowIndex >= 0) {
            var prevRow = getRowForIndex(selectedRowIndex, column);
            markRowAsActive(prevRow);
          } else {
            selectedColumnIndex = -1;
          }
        }
        return false;
      }
    })
    .on('keyup', function (e) {
      const keyCode = keyUtil.getKeyCodeFromEvent(e);

      if (keyCode === keyUtil.KEY.ESC) {
        closeResultDivFunction();
      } else if (keyCode === keyUtil.KEY.RETURN) {
        let row = getColumnRowForIndex(
          selectedColumnIndex,
          selectedRowIndex,
          resultDiv
        );

        if (row !== undefined) {
          location.href = getRowURL(row);
        } else if (getColumnCount(resultDiv) === 1) {
          const singleColumn = getColumnForIndex(0, resultDiv);
          const singleMoreHits = singleColumn.find('.sv-searchform2-more-hits');

          if (singleMoreHits.length === 1) {
            location.href = singleMoreHits.find('a').attr('href');
          }
        }
      }

      return false;
    })
    .on('input', function () {
      if (searchTimer !== undefined) {
        clearTimeout(searchTimer); // Reset timer every time a key is pressed
      }

      searchTimer = setTimeout(function () {
        // Reset search hit selection when the term is modified
        const term = inputField.val();
        selectedRowIndex = -1;
        selectedColumnIndex = -1;

        if (term === '' || term.length < 2) {
          resultDiv.css('display', 'none'); // Hide search result if less than 2 characters in the query
        } else {
          const queryURL = getPortletResourceUri(portletId, 'query');

          $.ajax({
            url: queryURL,
            data: {
              term: term,
            },
          })
            .fail(function (aResult) {
              resultDiv.html(
                '<div class="sv-searchform2-message">' +
                  i18n.getText(
                    'portlet.search.searchform2.searchform2',
                    'searchFailed',
                    aResult.status
                  ) +
                  '</div>'
              );
            })
            .done(function (aResult) {
              let results = 0;

              for (let i = 0; i < aResult.facets.length; i++) {
                results += aResult.facets[i].hits.length;
              }

              if (term === aResult.term) {
                // Only show result if the current term is the same as the returned one
                const facets = aResult.facets;

                resultDiv.empty();
                resultDiv.css('width', 'auto'); // Clear width from previous search

                const $searchResultsCount = portletDiv.find(
                  '.sv-search-results-count'
                );

                $searchResultsCount.text(
                  results +
                    ' ' +
                    i18n.getText(
                      'portlet.search.searchform2.searchform2',
                      'results'
                    )
                );

                if (facets.length === 0) {
                  const msgTemplate = _.template(messageTemplate);
                  resultDiv.append($(msgTemplate(aResult)));
                } else {
                  // Adjust facet width if client window is to small (some kind of responsive mode)
                  var clientWidth = document.documentElement.clientWidth;
                  var columnWidth = aResult.width / 2;
                  var contentColumn = aResult.contentColumns;
                  var resultWidth = columnWidth * facets.length;
                  var isContent = false;
                  var addedContent = false;
                  var htmlResult = '';

                  if (resultWidth > clientWidth) {
                    if (clientWidth > 300) {
                      // Scale down column width if more than 300px available
                      columnWidth = clientWidth / 2;
                      resultWidth = columnWidth * facets.length;
                    } else if (columnWidth > clientWidth) {
                      // Never allow columns wider than client width
                      columnWidth = clientWidth;
                      resultWidth = clientWidth;
                    } else {
                      resultWidth = columnWidth;
                    }
                  }

                  for (
                    var columnIndex = 0;
                    columnIndex < facets.length;
                    columnIndex++
                  ) {
                    const facet = facets[columnIndex];
                    let templateStr;

                    if (facet.type === 'content') {
                      isContent = true;
                      templateStr = getContentTemplate(
                        columnIndex,
                        columnWidth
                      );
                    } else if (facet.type === 'ugc') {
                      isContent = true;
                      templateStr = getUgcTemplate(columnIndex, columnWidth);
                    } else if (facet.type === 'users') {
                      templateStr = getUserTemplate(columnIndex, columnWidth);
                      isContent = false;
                    } else {
                      templateStr = '';
                      log.error(
                        'Skipping unsupported facet type ' + facet.type
                      );
                    }

                    resultDiv.css('width', resultWidth);
                    if (isContent) {
                      if (!addedContent) {
                        templateStr =
                          '<div class="sv-border-box sv-searchform2-content-container" style="width: ' +
                          columnWidth * contentColumn +
                          'px">' +
                          '   <<%= facetTag %> tabindex="0" class="sv-searchform2-content-header <%= facetTagClass %>"><%- name %></<%= facetTag %>>' +
                          templateStr;
                      }
                      addedContent = true;
                    } else {
                      if (addedContent) {
                        templateStr = '</div>' + templateStr;
                      }
                    }

                    var template = _.template(templateStr);
                    htmlResult += template(facet);
                  }

                  resultDiv.append($(htmlResult));

                  const resultList = resultDiv.find('ul.sv-user-search-list');
                  resultList
                    .find('li.sv-searchform2-search-hit')
                    .on('mouseenter', function () {
                      var row = $(this);
                      selectedColumnIndex = getColumnIndexForRow(row);
                      selectedRowIndex = getIndexForRow(row);
                      markColumnAsInactive(resultList);
                      markRowAsActive(row);
                    })
                    .on('mouseleave', function () {
                      selectedColumnIndex = -1;
                      selectedRowIndex = -1;
                      markRowAsInactive($(this));
                    })
                    .on('click', function (e) {
                      // Only navigate if not clicked on an <a href...
                      if (!$(e.target).is('a')) {
                        var row = $(this);
                        location.href = getRowURL(row);
                      }
                    });
                }

                // Position div
                const my = aResult.facetAlignment + ' top';
                const at = aResult.facetAlignment + ' bottom';
                inputField.removeAttr('aria-activedescendant');
                resultDiv.attr('aria-expanded', true);
                resultDiv.show();
                resultDiv.position({
                  my: my,
                  at: at,
                  of: inputField,
                  offset: '0 1',
                  collision: 'none',
                });
              }
            });
        }
      }, 200);

      return false;
    });
};

// Initialize JavaScript for each searchform2 portlet
$('.sv-searchform2-portlet').each((a, portlet) => {
  const $portlet = $(portlet),
    portletId = objectUtil.getObjectId($portlet.attr('id')),
    resultDiv = $portlet.find('.sv-searchform2-result-container'),
    inputField = $portlet.find('.sv-searchform2-input');

  inputField.attr('autocomplete', 'off'); // Disable autocomplete without breaking W3C validation

  showSearchResultPopup({
    portletId: portletId,
    inputField: inputField,
    resultDiv: resultDiv,
    portletDiv: $portlet,
  });
});
