/**
 * Copyright (C) SiteVision AB 2002-2019, all rights reserved
 *
 * Handles oembed media in timelines and embeddedmedia portlet
 *
 * @author Karl Eklöf
 */

import sv from '@sv/core';
import {
  Ajax as ajax,
  Events as events,
  TemplateUtil as templateUtil,
} from '@sv/util';
import { getModelObjectUri } from '../portletUtil';

const videoTemplate =
    '<div class="sv-oembed-container">' +
    '<div class="sv-iframeEmbed-16_9">' +
    '<%= html %>' +
    '</div>' +
    '</div>',
  socialMediaPostTemplate =
    '<div class="sv-oembed-container">' + '<%= html %>' + '</div>';

const providers = [
  {
    name: 'Dreambroker',
    scheme: new RegExp('dreambroker.com/channel/.+'),
    callback: function (href, embedFn) {
      ajax
        .doGet({
          url: getModelObjectUri(sv.PageContext.pageId, 'embed'),
          data: {
            url: href,
            provider: 'dreambroker',
          },
        })
        .done(function (response) {
          if (response && response.html) {
            embedFn(
              templateUtil.template(videoTemplate, { html: response.html })
            );
          }
        });
    },
  },
  {
    name: 'Instagram',
    scheme: new RegExp('instagr\\.?am(\\.com)?/.+'),
    callback: function (href, embedFn) {
      ajax
        .doGet({
          url: 'https://api.instagram.com/oembed',
          data: {
            url: href,
          },
        })
        .done(function (response) {
          if (response && response.html) {
            embedFn(
              templateUtil.template(socialMediaPostTemplate, {
                html: response.html,
              })
            );
          }
        });
    },
  },
  {
    name: 'Youtube',
    scheme: new RegExp('^(https?://)?(www.)?(youtube.com|youtu.?be)/.+$'),
    callback: function (href, embedFn) {
      var found = href.match(/.*(?:v\=|be\/|embed\/)([\w\-]+)&?.*/);

      if (found && found.length > 0) {
        var src = 'https://www.youtube.com/embed/' + found[1],
          html =
            '<iframe src="' +
            src +
            '" allowfullscreen="true" frameborder="0"></iframe>';

        embedFn(templateUtil.template(videoTemplate, { html: html }));
      }
    },
  },
  {
    name: 'GIPHY',
    scheme: new RegExp('giphy.com/.*'),
    callback: function (href, embedFn) {
      var html = '<img class="giphy-img" src="' + href + '" alt="GIPHY">';

      embedFn(templateUtil.template(socialMediaPostTemplate, { html: html }));
    },
  },
  {
    name: 'Youtube no cookie',
    scheme: new RegExp('^(https?://)?(www.)?(youtube-nocookie.com)/.+$'),
    callback: function (href, embedFn) {
      var found = href.match(/.*(?:v\=|be\/|embed\/)([\w\-]+)&?.*/);

      if (found && found.length > 0) {
        var src = 'https://www.youtube-nocookie.com/embed/' + found[1],
          html =
            '<iframe src="' +
            src +
            '" allowfullscreen="true" frameborder="0"></iframe>';

        embedFn(templateUtil.template(videoTemplate, { html: html }));
      }
    },
  },
  {
    name: 'Vimeo',
    scheme: new RegExp('vimeo.com/.*'),
    callback: function (href, embedFn) {
      ajax
        .doGet({
          url: 'https://vimeo.com/api/oembed.json',
          data: {
            url: href,
          },
        })
        .done(function (response) {
          if (response && response.html) {
            embedFn(
              templateUtil.template(videoTemplate, { html: response.html })
            );
          }
        });
    },
  },
  {
    name: 'Wistia',
    scheme: new RegExp('https?://(.+)?(wistia.com|wi.st)/.*'),
    callback: function (href, embedFn) {
      ajax
        .doGet({
          url: 'https://fast.wistia.com/oembed',
          data: {
            url: href,
          },
        })
        .done(function (response) {
          if (response && response.html) {
            embedFn(
              templateUtil.template(videoTemplate, { html: response.html })
            );
          }
        });
    },
  },
  {
    name: 'Screen9',
    scheme: new RegExp('screen9.com/.+|screen9.tv/.+'),
    callback: function (href, embedFn) {
      ajax
        .doGet({
          url: 'https://api.screen9.com/oembed',
          data: {
            url: href,
          },
        })
        .done(function (response) {
          if (response && response.html) {
            embedFn(
              templateUtil.template(videoTemplate, { html: response.html })
            );
          }
        });
    },
  },
  {
    name: 'Soundcloud',
    scheme: new RegExp('soundcloud.com/.+|snd.sc/.+'),
    callback: function (href, embedFn) {
      ajax
        .doGet({
          url: 'https://soundcloud.com/oembed',
          data: {
            url: href,
          },
        })
        .done(function (response) {
          if (response && response.html) {
            embedFn(
              templateUtil.template(videoTemplate, { html: response.html })
            );
          }
        });
    },
  },
  {
    name: 'Spotify',
    scheme: new RegExp('open.spotify.com/.+'),
    callback: function (href, embedFn) {
      ajax
        .doGet({
          url: getModelObjectUri(sv.PageContext.pageId, 'embed'),
          data: {
            url: href,
            provider: 'spotify',
          },
        })
        .done(function (response) {
          if (response && response.html) {
            embedFn(
              templateUtil.template(videoTemplate, { html: response.html })
            );
          }
        });
    },
  },
];

/**
 * Finds links within the scope and start oembed.
 */
const updateOembedLinks = function ($scope, options) {
  if (!$scope || !$scope.length) {
    return;
  }

  let $embed = $scope.find('.sv-embed').first();

  if (!$embed.length) {
    return;
  }

  let href = $embed.attr('href');

  if (!href) {
    return;
  }

  for (var i = 0; i < providers.length; i++) {
    let provider = providers[i];

    if (provider.scheme.test(href)) {
      provider.callback(href, getEmbedFn(options && options.replace, $scope));
      break;
    }
  }
};

const getEmbedFn = function (replace, $scope) {
  if (replace) {
    return function (html) {
      $scope.find('.sv-embed').first().replaceWith(html);
    };
  }

  return function (html) {
    $scope.find('.sv-message-content > .sv-embed').parent().first().after(html);
  };
};

events.on(events.types.updateOembedLinks, updateOembedLinks);
