/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author robin
 */
import $ from '@sv/jquery';

const $resizableImages = $('a.sv-resizable-img');
const $lazyLoadImages = $('img.sv-lazy-load-img');

function initResizableImages($resizableImages) {
  $resizableImages.fancybox({
    hideOnContentClick: true,
    helpers: { title: null },
    transitionIn: 'elastic',
    transitionOut: 'elastic',
    padding: 0,
    overlayOpacity: 0.6,
  });
}

if ($resizableImages.length) {
  import(/* webpackChunkName: "fancybox" */ '../vendor/fancybox').then(() => {
    initResizableImages($resizableImages);
  });
}

if ($lazyLoadImages.length) {
  import(/* webpackChunkName: "yall" */ '../vendor/yall').then(({ default: yall }) => {
    yall({ lazyClass: 'sv-lazy-load-img' });
  });
}
