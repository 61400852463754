import $ from '@sv/jquery';
import sv from '@sv/core';

export const imgSlideshow = function (selector, options) {
  import(/* webpackChunkName: "slideshow-plugins" */ './plugins').then(() => {
    options = options || {};
    $(selector).find('.sv-carousel').first().svCarousel(options);
  });
};

sv.imgSlideshow = imgSlideshow;
