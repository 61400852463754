/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author robin
 */
import $ from '@sv/jquery';

const sizeFieldCache = {};

$('.sv-has-error').each(function () {
  const $this = $(this);

  $this.on('change keyup', function () {
    $this.removeClass('sv-has-error');
    $this.off();
  });
});

const countdownCharactersLeft = function ($target) {
  const value = $target.val(),
    text = value ? value.trim() : '',
    sizeField = sizeFieldCache[$target.attr('id')],
    size = sizeField.initialSize - text.length; //Text is trimmed server side

  if (size < 0) {
    sizeField.$el.addClass('sv-textfield-limit-exceeded');
  } else {
    sizeField.$el.removeClass('sv-textfield-limit-exceeded');
  }

  sizeField.$el.text(size);
};

$('.sv-form-textfield.sv-limit-characters').each(function () {
  const $this = $(this),
    sizeField = $this.parent().find('.sv-textfield-input-size'),
    id = $this.attr('id');

  sizeFieldCache[id] = {
    $el: sizeField,
    initialSize: sizeField.text(),
  };

  $this.on('keyup', function (e) {
    countdownCharactersLeft($(e.currentTarget));
  });

  if ($this.val().length) {
    countdownCharactersLeft($this);
  }
});

$('form[data-sv-single-click]').one('submit', function () {
  $(this).find('input[type=submit]').prop('disabled', true);
});

$('form[data-sv-user-consent]').each(function () {
  const $this = $(this),
    $submit = $this.find('input[type=submit]:not(.sv-survey-next)');

  if ($submit.length) {
    const $consentCheckbox = $this.find('[data-sv-form-consent-checkbox]');

    $submit.prop('disabled', !$consentCheckbox.is(':checked'));

    $consentCheckbox.on('change', function () {
      $submit.prop('disabled', !$(this).is(':checked'));
    });
  }
});
