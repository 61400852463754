/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 */
import $ from '@sv/jquery';
import sv from '@sv/core';
import { imgSlideshow } from '../util/slideShow/sv-imgSlideshow';

function createAndLoadSlideshowForId(portletId) {
  const $portlet = $(portletId),
    id = '#' + $portlet.attr('id'),
    carousel = $portlet.find('.sv-carousel'),
    autoplay = carousel.data('fn-autoplay'),
    interval = carousel.data('fn-interval'),
    options = { autoplay: autoplay };

  if (autoplay && interval) {
    options.autoplayTimeout = interval;
  }

  imgSlideshow(id, options);
}

sv.createAndLoadSlideshowForId = createAndLoadSlideshowForId;
